import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Datenschutz",
  "author": "Jochen Ritscher",
  "categories": ["faqs"],
  "date": "2021-07-30T00:00:00.000Z",
  "featured": false
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Das Inkrafttreten der DSGVO stellt eine Herausforderung für Unternehmen und die Recruiting / Personalabteilung dar. Bis zum 25. Mai 2018 müssen Sie sich mit dem neuen Gesetz beschäftigen und die wichtigsten Schritte eingeleitet haben. Ihre Karriereseiten und Recruiting-Prozesse müssen an die neue Gesetzeslage angepasst werden. Inbesondere die Zweckbindung spielt eine große Rolle – d.h. ist die Stelle besetzt entfällt der Zweck der Aufbewahrung der personenbezogene Daten und diese müssen somit gelöscht werden. Falles es jedoch z.B. aufgrund des Allgemeinen Gleichbehandlungsgesetzes (AGG) zu einer Klage von Seiten der Bewerber kommen kann, dürfen Sie Daten länger aufbewahren, hier spricht man aktuell von zwei bis sechs Monaten. Nur bei schriftlicher Einwilligung des Bewerbers dürfen Daten im Bedarfsfall länger aufbewahrt werden.`}</p>
    <h2>{`Wie können Sie die DSGVO umsetzen?`}</h2>
    <p>{`Folgende Fragen müssen Sie u.a. für Ihre Unternehmen klären und dokumentieren, da Sie stets in der Pflicht sind nachzuweisen, dass Sie die Datenschutzgesetze einhalten:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Woher kommen Bewerbungen und Kandidatenvorschläge?`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Informieren wir Bewerber bei Eingang der Unterlagen über die Art der Datenerhebung?`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Wer erhält Bewerbungen?`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Wo werden welche Bewerberdaten wie lange und zu welchem Zweck gespeichert?`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Haben wir die Genehmigung des Bewerbers Daten aufzubewahren?`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Können wir löschen und werden Bewerberdaten automatisch gelöscht?`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Bei Verstößen schlägt das Gesetz zu, mit Höchststrafen bis zu 4% des weltweiten Umsatzes. Damit ist ein Datenschutzverstoß spätestens 2018 kein Kavaliersdelikt mehr. Wichtig: Datenschutz kann man nicht einfach installieren, Datenschutz muss man leben.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Was für viele Unternehmen eine echte Herausforderung darstellt, ist für Sie eine Chance Ihren  Recruiting-Prozess neu aufzustellen. Schaffen Sie mit einer neuen E-Recruiting-Software neben der notwendigen Transparenz auch effizientere Prozesse. Wie sie das schaffen? Wir beraten Sie gerne.`}</p>
      </li>
    </ul>
    <h2>{`Nützliche Dokumente`}</h2>
    <p>{`Datenschutzpaket für gehostete Produkte in der inriva-Cloud
`}<a parentName="p" {...{
        "href": "https://www.ionos.de/terms-gtc/terms-enterprise-cloud/datenschutzpaket/"
      }}>{`https://www.ionos.de/terms-gtc/terms-enterprise-cloud/datenschutzpaket/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      